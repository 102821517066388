@import './../../../../scss/theme-bootstrap';

.cf-banner {
  display: flex;
  flex-direction: column;
  @include breakpoint($large-up) {
    flex-direction: row;
    &__header,
    &__footer {
      .site-footer-wrapper & {
        padding-top: 25px;
      }
    }
  }
  &__header {
    text-transform: uppercase;
    .site-header__banner & {
      text-transform: uppercase;
    }
    .site-footer__banner & {
      @include font--bodoni-bold;
      font-size: 40px;
      text-transform: uppercase;
      @include breakpoint($medium-up) {
        font-size: 26px;
      }
      @include breakpoint($large-up) {
        line-height: 1;
      }
      @include breakpoint($xlarge-up) {
        font-size: 28px;
      }
    }
  }
  &__footer {
    .site-header__banner & {
      text-transform: capitalize;
    }
    .site-footer__banner & {
      @include font--proxima-nova;
      font-size: 25px;
      @include breakpoint($large-up) {
        line-height: 1;
      }
      @include breakpoint($xlarge-up) {
        font-size: 28px;
      }
    }
  }
  // region / placement specific styles
  .site-header__banner & {
    @include title-small;
  }
  .site-footer__banner & {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-transform: uppercase;
    width: 100%;
    padding: 12px 2%;
  }
}
